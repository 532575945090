import { Link } from "gatsby";
import {useIntersector} from './customhooks'
import PropTypes from "prop-types";
import React, {useState, useRef} from "react";
import { useMediaQuery } from 'react-responsive';
import Navigation from './navigation'


const Header = () => {

    const [isIntersecting, setIsIntersecting] = useState(true);
    const eInterTarget = useRef();


    const mobileMenu = useMediaQuery({ query: '(max-width: 60em)' })
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useIntersector({
        eInterTarget, 
        rootMargin: "50px",
        onIntersect: ([{isIntersecting}]) => setIsIntersecting(isIntersecting)
    });
   
    return (
        <>
       
        <header className={`${!isIntersecting ? "scroll" : 'no-scroll'} ${mobileMenuOpen ? "menu-open" : ""}`}>
        
            
            <div className="inner">
                <Link className="logo" to="/"></Link>

            {mobileMenu && (
                <>
               
                <button className="menu-button" onClick={() => setMobileMenuOpen(!mobileMenuOpen)} aria-label="toggle menu"><span></span></button>

                </>
            )}
            <Navigation />

            </div>
        </header>

        {mobileMenu && (<button className={`mobile-exit ${mobileMenuOpen ? "menu-open" : ""}`} onClick={() => setMobileMenuOpen(false)} aria-label="exit-menu"></button>)}

        <div className="scroll-intersect" ref={eInterTarget}></div>
        </>
    )

}

Header.propTypes = {
    siteTitle: PropTypes.string,
}
  
Header.defaultProps = {
    siteTitle: ``,
}


export default Header;