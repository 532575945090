import React from 'react'
import { Link } from "gatsby";

const Navigation = () => {

    return (

        <nav> 

            <ul>
                <li className="first-item main-item">
                    <Link to='/'><span>Home</span></Link>
                </li>
                <li className="main-item">
                    <Link to='/about-us'><span>About Us</span></Link>
                </li>
                <li className="has-submenu main-item">
                    <span>Our Work 
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6.175" viewBox="0 0 10 6.175">
                        <path id="Icon_material-keyboard-arrow-down" data-name="Icon material-keyboard-arrow-down" d="M10.175,11.76,14,15.577l3.825-3.817L19,12.935l-5,5-5-5Z" transform="translate(-9 -11.76)" fill="#fff"/>
                    </svg>
                    </span>
                    <ul className="sub-menu">
                        <li className="first-item">
                            <Link to="/new-builds">
                                <span>New Builds</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/additions-and-renovations">
                                <span>Additions / Renovations</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/reclads">
                                <span>Reclads</span>
                            </Link>
                        </li>

                    </ul>
                </li>
                <li className="main-item">
                    <Link to='/how-we-work'><span>How We Work</span></Link>
                </li>
                <li className="main-item">
                    <Link to='/contact-us'><span>Contact Us</span></Link>
                </li>

            </ul>
        </nav>
       
    )

}

export default Navigation