import React from 'react';
import Navigation from './navigation';

const Footer = () => {

    return (

        <footer>
            <div className="inner">
                <div className="footer-top">               
                    <div className="logo"></div>
                    <Navigation />
                </div>
                <div className="footer-bottom">

                    <div className="contact-details">
                        <div className="contact-address"><span className="title">Address:</span><span> 86 Marine Parade, Mellons Bay, Auckland 2014</span></div>
                        <div className="contact-phone"><span className="title">Phone:</span> <a href="tel:+6495337445">09 533 7445</a></div>
                        <div className="contact-mobile"><span className="title">Mobile:</span> <a href="tel:+64274962129">0274 962 129</a></div>
                        <div className="contact-email"><span className="title">Email:</span> <a href="mailto:info@modusdevelopments.nz">info@modusdevelopments.nz</a></div>
                    </div>
                    
                    <p className="copyright">&copy; Modus Developments {new Date().getFullYear()}</p>
                </div>
            </div>


        </footer>
       
    )

}

export default Footer